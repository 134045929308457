import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import DefaultLayout from '@/layouts/default';
import SEO from '@/components/layout/seo';
import { useForm } from 'react-hook-form';
import { createNewUser } from '@/services/api';
import { Link } from 'gatsby';
import { FaArrowLeft, FaGrinBeam } from 'react-icons/fa';
import Masthead from '@/components/layout/masthead';
import IdmMastheadContent from '@/components/IdmMastheadContent';
import LoginContent from '@/components/LoginContent';

const Page = () => {
  const { register: newUser, getValues: getNewUserValues } = useForm();

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const formChanged = async (e, form) => {
    e.preventDefault();
    setError(false);
    setSubmitting(true);

    let userData = getNewUserValues();

    if (userData.password !== userData.confirm_password) {
      setError('The passwords entered do not match.');
    } else {
      let res = await createNewUser(userData);
      if (res.error) {
        let err = '';
        err = Object.entries(res.errors).map((itm) => {
          return err + itm[1] + '<br />';
        });
        setError(err.join(' '));
      } else {
        setError(false);
        setSuccess(true);
      }
    }
    setSubmitting(false);
  };

  return (
    <DefaultLayout>
      <SEO title="Register" keywords={[]} />
      <Masthead>
        <IdmMastheadContent />
      </Masthead>
      <Container className="my-0">
        <Row>
          <Col lg={6} className="mt-5 my-lg-5">
            <LoginContent />
          </Col>

          <Col lg={{ span: 5, offset: 1 }} className="mt-3 my-lg-5 shadow p-5">
            <h3>Account Registration</h3>
            {success === false ? (
              <div>
                <p>All fields below are required.</p>
                {error && (
                  <div
                    className="alert alert-warning"
                    dangerouslySetInnerHTML={{ __html: error }}
                  ></div>
                )}
                <Form onSubmit={(e) => formChanged(e)}>
                  <Form.Group controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your first name"
                      ref={newUser}
                      name="first_name"
                    />
                  </Form.Group>
                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your last name"
                      ref={newUser}
                      name="last_name"
                    />
                  </Form.Group>
                  <Form.Group controlId="organization">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your organization"
                      ref={newUser}
                      name="organization"
                    />
                  </Form.Group>
                  <Form.Group controlId="state">
                    <Form.Label>State/Province</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your State/Province (e.g. WA, OH, CA)"
                      ref={newUser}
                      name="state"
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email address"
                      ref={newUser}
                      name="email"
                    />
                  </Form.Group>
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      ref={newUser}
                      name="password"
                    />
                    <Form.Text>
                      Your password must:
                      <ul>
                        <li>be at least 8 characters</li>
                        <li>
                          contain at least one uppercase and one lowercase
                          letter
                        </li>
                        <li>contain at least one numeric character</li>
                        <li>contain at least one symbol</li>
                      </ul>
                    </Form.Text>
                  </Form.Group>{' '}
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm password"
                      ref={newUser}
                      name="confirm_password"
                    />
                  </Form.Group>
                  <Form.Group controlId="emailOptIn">
                    <Form.Check 
                      type="checkbox" 
                      label="We may send you promotional materials as well as information regarding important upcoming events and seminars .  If you prefer to not receive these emails from us, 
                      please uncheck this box."
                      ref={newUser} 
                      name="email_opt_in" 
                      defaultChecked="checked"
                      />
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="cute-button"
                    type="submit"
                  >
                    {submitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span> Register</span>
                    )}
                  </Button>
                </Form>
              </div>
            ) : (
              <div className="d-flex">
                <div>
                  <FaGrinBeam
                    size="50px"
                    className="text-idm-pink mr-2 mt-2"
                    style={{ width: '60px' }}
                  />
                </div>
                <h5 className="mt-0">
                  Your account has been successfully created. You may now return
                  to the login page and then log in.
                </h5>
              </div>
            )}
            <p className="mt-4">
              <Link to="/login/">
                <FaArrowLeft /> Return to Login
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

export default Page;
